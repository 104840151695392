import React from "react"
import { Link, graphql } from "gatsby"

//utilities
import { rhythm, scale } from "../utils/typography"

//components
import Img from "gatsby-image"
import Bio from "../components/bio"
import IndexLayout from "../components/IndexLayout"
import SEO from "../components/seo"
import Tag from "../components/Tag"
import { DiscussionEmbed } from "disqus-react"
import Newsletter from "../components/newsletter"
import { CalendarAlt } from "styled-icons/fa-regular"
import { Clock } from "styled-icons/fa-regular"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    //check if there is a featured image
    const isFeaturedImage = post.frontmatter.featuredImage
    const featuredImageFluid = isFeaturedImage
      ? isFeaturedImage.childImageSharp.fluid
      : null
    const featuredImageCredit = post.frontmatter.featuredImageCredit

    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next, slug } = this.props.pageContext

    //config for disqus comments
    const disqusConfig = {
      shortname: process.env.GATSBY_DISQUS_NAME,
      config: {
        identifier: slug,
        siteTitle,
      },
    }

    return (
      <IndexLayout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={featuredImageFluid.src}
        />
        <article>
          <header>
            <h1
              style={{
                marginTop: rhythm(1),
                marginBottom: 0,
              }}
            >
              {post.frontmatter.title}
            </h1>
            <p
              style={{
                ...scale(-1 / 5),
                display: `block`,
                marginBottom: rhythm(1),
              }}
            >
              <CalendarAlt
                style={{
                  width: `1em`,
                  marginRight: `6px`,
                }}
              />
              {post.frontmatter.date} -
              <Clock
                style={{
                  width: `1em`,
                  marginLeft: `6px`,
                  marginRight: `6px`,
                }}
              />
              {post.timeToRead} min de lecture
            </p>
            {post.frontmatter.tags &&
              post.frontmatter.tags.map(tag => {
                return <Tag tag={tag} key={tag} />
              })}
          </header>
          {isFeaturedImage && <Img fluid={featuredImageFluid} />}
          {featuredImageCredit && (
            <p
              style={{ textAlign: "center", ...scale(-1 / 9), opacity: "0.6" }}
            >
              <em>{featuredImageCredit}</em>
            </p>
          )}
          <section
            style={{ maxWidth: `90vw` }}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <Newsletter />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <footer>
            <Bio />
          </footer>
        </article>
        <DiscussionEmbed {...disqusConfig} />
        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </IndexLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
              src
            }
          }
        }
        featuredImageCredit
      }
    }
  }
`
